@import "../../../styles/vars";

.main-img__wrapper {
  //position: relative;
  border-left: 1px solid $accent-color;
  border-right: 1px solid $accent-color;
  width: calc(100vw - 24px);
  overflow: hidden;
}
.main-img__box {
  width: 2220px;
  height: 248px;
  transition: transform 250ms ease-out;
  & img {
    width: 2220px;
    height: 248px;
    object-fit: cover;
  }
}
@media screen and (min-width: 820px) {
  .main-img__box, .main-img__box img {
    width: 2488px;
    height: 278px;
  }
}
@media screen and (min-width: 1440px) {
  .main-img__box, .main-img__box img {
    width: 3509px;
    height: 392px;
  }
}
@media screen and (min-width: 1920px) {
  .main-img__box, .main-img__box img {
    width: 4673px;
    height: 522px;
  }
}
@media screen and (min-width: 4673px) {
  .main-img__box, .main-img__box img {
    margin: auto;
    transform: translateX(0);
  }
}